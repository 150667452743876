import { Col, Row } from "antd";
import React, { useState, useEffect } from "react";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import { useTranslation } from "react-i18next";
import useWidth from "../Utils/useWidth";

function HeaderBar() {

  const width = useWidth()
  const [expandedMenu, setExpandedMenu] = useState(false);


  useEffect(() => {
    adaptNavbar()
  }, [width])

  const toggleTheMobileMenu = () => {
    setExpandedMenu(!expandedMenu);
  }

  function adaptNavbar() {
    if (window.innerWidth >= 992) {
      setExpandedMenu(false);
    }
  }

  return (
    <div className="HeaderBar">
      <Row align={"middle"} justify="space-between" style={{ width: "100%" }}>
        <Col span={8}>
          <div className="Logo" />
        </Col>
        <Col xs={0} lg={8}>
          <div className="retailer-dashboard-logo-container">
            <div className="retailer-dashboard-logo" />
          </div>
        </Col>
        <Col xs={24} lg={0}>
          <ExtendedHeader expandedMenu={expandedMenu} />
        </Col>
      </Row>
      <div>
        {expandedMenu ? (
          <AiOutlineClose className="MobileMenuIcon" onClick={toggleTheMobileMenu} />) : (
          <AiOutlineMenu className="MobileMenuIcon" onClick={toggleTheMobileMenu} />)}
      </div>
    </div>
  );
}

function ExtendedHeader({ expandedMenu }) {

  const { t } = useTranslation();

  return (
    <div className={expandedMenu ? "ExpandedHeaderLinks" : "HeaderLinks"}>
      <span className="HeaderLink">
        <a
          href={t("mainScreen.headerBar.dataLink")}
          rel="noopener noreferrer"
          target="_blank"
        >
          {t("mainScreen.headerBar.mercedesBenzData")}
        </a>
      </span>
      <span className="HeaderLink">
        <a
          href="https://developer.mercedes-benz.com/products?u=Retailer"
          rel="noopener noreferrer"
          target="_blank"
        >
          {t("mainScreen.headerBar.products")}
        </a>
      </span>
      <span className="HeaderLink">
        <a
          href="https://developer.mercedes-benz.com/contact/vehicle_images/business_inquiry"
          rel="noopener noreferrer"
          target="_blank"
        >
          {t("mainScreen.headerBar.contact")}
        </a>
      </span>
      <span className="HeaderLink">
        <a
          href="https://developer.mercedes-benz.com/contact/vehicle_images/technical_inquiry"
          rel="noopener noreferrer"
          target="_blank"
        >
          {t("mainScreen.headerBar.support")}
        </a>
      </span>
    </div>
  );
}

export default HeaderBar;