import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import { useState, useRef } from 'react'
import { Layout } from 'antd'

import HeaderBar from './Components/HeaderBar'
import Dashboard from './Components/Dashboard'
import FooterBar from './Components/FooterBar'

import './App.css';
import SubHeaderBar from './Components/SubHeaderBar'
import ProductKey from './Components/ProductKey'

const { Header, Content, Footer } = Layout;

function App() {
  return (
    <Router>
      <Switch>
      <Route exact path="/home">
          <Page tab={"1"}/>
        </Route>
        <Route exact path="/images">
          <Page tab={"1"}/>
        </Route>
        <Route exact path="/equipments">
          <Page tab={"2"}/>
        </Route>
        <Route exact path="/videos">
          <Page tab={"3"}/>
        </Route>
        <Route exact path="/specification">
          <Page tab={"4"}/>
        </Route>
        <Route exact path="/models">
          <Page tab={"5"}/>
        </Route>
        <Route path="*" >
          <Redirect to="/images" />
        </Route>
      </Switch>
    </Router>
  );
}

function Page(props) {
  const { tab } = props
  const [apikeyEntered, setApikeyEntered] = useState([0, 0, 0, 0, 0]);
  const apikeyRef = useRef([0, 0, 0, 0, 0]);
  const [selectedTab, setSelectedTab] = useState(tab);
  return (
    <div className="App">
      <Layout>
        <Header>
          <HeaderBar />
        </Header>
        <SubHeaderBar />
        <Content>
          <Dashboard selectedTab={selectedTab} setSelectedTab={setSelectedTab} apikeyEntered={apikeyEntered} setApikeyEntered={setApikeyEntered} apikeyRef={apikeyRef}/>
          <ProductKey selectedTab={selectedTab} apikeyEntered={apikeyEntered} />
        </Content>
        <Footer>
          <FooterBar />
        </Footer>
      </Layout>
    </div>
  )
}

function NotFoundPage() {
  return (
    <div>404 Not Found</div>
  )
}

export default App;
